<template>
  <div class="main-box">
    <el-form class="form-inline" :inline="true" size="small">
      <el-form-item label="">
        <el-input v-model="search.user" placeholder="请输入会员" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-input v-model="search.keywords" placeholder="请输入订单号" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-select v-model="search.order_type" placeholder="请选择订单类型" clearable>
          <el-option :key="1" label="商城订单" :value="1"></el-option>
          <el-option :key="2" label="充值" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-select v-model="search.trade_type" placeholder="请选择交易类型" clearable>
          <el-option :key="1" label="支付" :value="1"></el-option>
          <el-option :key="2" label="退款" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="下单时间">
        <el-date-picker
            size="small"
            v-model="dateRange"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" size="small" @click="handleSearch">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-table
        :data="tableData"
        ref="rfTable"
        style="width: 100%"
        size="mini"
        @selection-change="handleSelectionChange">
      <el-table-column
          prop="name"
          label="充值用户"
          align="center"
          width="250">
        <template slot-scope="scope">
          <div v-if="scope.row.name" class="member-box">
            <img :src="scope.row.avatar" />
            <div>
              <div>{{scope.row.name}}（{{scope.row.user_id}}）</div>
              <div>{{scope.row.mobile}}</div>
            </div>
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column
          prop="amount"
          label="支付金额"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="order_type"
          label="订单类型"
          align="center"
          width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.order_type==1">商城订单</span>
          <span v-if="scope.row.order_type==2">充值</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="trade_type"
          label="交易类型"
          align="center"
          width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.trade_type==1">支付</span>
          <span v-if="scope.row.trade_type==2">退款</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="order_sn"
          label="订单编号"
          align="center"
          width="200">
        <template slot-scope="scope">
          {{scope.row.order_sn}}
          <el-button type="text" v-if="scope.row.order_id" @click="goOrder(scope.row)" size="small">查看</el-button>
        </template>
      </el-table-column>
      <el-table-column
          prop="payment_sn"
          label="交易单号"
          align="center"
          width="200">
      </el-table-column>
      <el-table-column
          prop="trade_sn"
          label="支付单号"
          align="center"
          width="250">
      </el-table-column>
      <el-table-column
          prop="pay_time"
          label="支付时间"
          align="center"
          width="150">
      </el-table-column>
      <el-table-column
          prop="ftime"
          label="下单时间"
          align="center"
          width="150"
          fixed="right">
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[10, 20, 30, 40]"
        :total="search.total">
    </el-pagination>
  </div>
</template>
<script>

export default {
  components: {},
  data() {
    return {
      info: false,
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: '',
        user: '',
        order_type: '',
        trade_type: ''
      },
      tableData: [],
      dateRange: ['','']
    };
  },
  created() {
    this.getList();
  },
  mounted() {
  },
  computed: {},
  methods: {
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit,
        keywords: that.search.keywords,
        user: that.search.user,
        order_type: that.search.order_type,
        trade_type: that.search.trade_type,
      }
      if(this.dateRange) {
        param.start_time = this.dateRange[0]
        param.end_time = this.dateRange[1]
      }
      this.$api.order.orderTradeIndex(param, function (res) {
        that.hideLoading()
        if (res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    },
    goOrder(item) {
      if(item.trade_type==1) {
        window.open('/order/detail/' + item.order_id)
      } else if(item.trade_type==2) {
        window.open('/order/refund/detail/' + item.order_id)
      }
    }
  }
};
</script>
<style scoped>
</style>
